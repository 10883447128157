<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getInfo"
    >
        <div slot=body>
			<!-- <Banner v-if="getOrgInfo && getOrgInfo.id && getOrgInfo.id ==='caa1e2c1-a68d-e911-a9c5-38d547b912d4'"/> -->
			<Banner v-if="orgId" :orgId="orgId"/>
			<div class="Main">
				<section class="content">
					<div class="content-header" v-if="announcement.length">
						<div class="wrapper">
							<p class="content-header__text">объявления</p>
						</div>
					</div>
					<div class="content-wrap"  v-if="announcement.length">
						<div class="wrapper">

							<div class="advertisement-col" :class="`color-advent-${item.color}`" v-for="(item, index) in announcement" :key="index">
								<span>{{ dateReturnFormat(item.dateCreate) }}</span>
								<h3 class="advertisement-col__title">{{ item.title }}</h3>
								<p>{{ item.description }}</p>
							</div><!-- col(1) -->

						</div>
					</div>

					<div class="wrapper">
						<div class="news-content">
							<h2 class="news-content__title">Добро пожаловать на официальный сайт {{ getOrgInfo.organizationName }}</h2>
							
<!-- 							<a
								class="corona"
								style="padding: 20px;border: 2px dashed red;margin-bottom: 20px;color: red;text-align: center;font-weight: bold; display: block;"
								href="/img/korona.jpg"
								target="_blank"
							>
								В связи с пандемией коронавируса, настоятельно рекомендуем ознакомиться с рекомендациями ВОЗ.
							</a> -->
							<div style="overflow-x: auto;">
								<span class="ql-editor" v-html="about" style="display: table"></span>
							</div>
							
							
							<div v-if="!about">

								<h3 class="entry-block__title">Сайт создан для донесения до собственников помещений следующей информации:</h3>
								<ul class="entry-block__list">
									<li>данных об управляющей организации;</li>
									<li>основных показателей финансово-хозяйственной деятельности управляющей организации в части исполнения договоров управления;</li>
									<li>сведений о выполненных работах и оказанных услугах по содержанию и ремонту общего имущества в многоквартирном доме;</li>
									<li>порядке и условиях оказания услуг по содержанию и ремонту общего имущества в многоквартирном доме;</li>
									<li>сведений о стоимости работ и услуг по содержанию и ремонту общего имущества в многоквартирном доме;</li>
									<li>сведений о тарифах на коммунальные ресурсы.</li>
								</ul>

								<div style="text-align: center;"><img src="@/img/people.jpg"></div>

								<h3 class="entry-block__title">Функционал, доступный собственникам после авторизации на сайте с помощью сервиса «Квартплата.онлайн»:</h3>

								<ul class="entry-block__list">
									<li>подробная информация о начислениях, поступивших платежах и текущему балансу каждого лицевого счета;</li>
									<li>передача показаний приборов учета в режиме онлайн;</li>
									<li>оплата задолженности в один клик;</li>
									<li>возможность распечатать счет-квитанцию на оплату ЖКУ;</li>
									<li>возможность добавить любое количество помещений в единый личный кабинет;</li>
									<li>возможность получать уведомления в мобильном приложении о сроках передачи показаний, задолженности и пр. </li>
								</ul>
							</div>
						</div>
					</div>
				</section>
			</div>
        </div>
    </appGloballayout>
</template>

<script>
import { dateReturnFormat } from '@/plugins/methods'
import { mapGetters } from 'vuex'
import Banner from '../../components/banner/banner.vue'

export default {
    name: "index",
    data() {
        return {
            announcement: [],
            about: "",
            loading: true,
            error: false,
			showBanner: false,
        };
    },
    computed: {
        ...mapGetters([
            "getOrgInfo"
        ]),

		orgId(){
			if(this.getOrgInfo && this.getOrgInfo.id && this.getOrgInfo.id ==='caa1e2c1-a68d-e911-a9c5-38d547b912d4'){
				//ООО "САТЕЛЛИТ - ЮГ"
				return 336899
			} else if (this.getOrgInfo && this.getOrgInfo.id && this.getOrgInfo.id ==='afa0e2c1-a68d-e911-a9c5-38d547b912d4') {
				//МУП "ЮГ"
				return 313851
			} else if (this.getOrgInfo && this.getOrgInfo.id && this.getOrgInfo.id === '12a1e2c1-a68d-e911-a9c5-38d547b912d4') {
				//ООО "Управляющая Компания  "Министерские Озера"
				return 327454
			// } else if (this.getOrgInfo && this.getOrgInfo.id && this.getOrgInfo.id === '14935883-379e-4f2b-b5f7-fb8c3d20b207') {
			// 	//ООО УК "ВЕКТОР"
			// 	return 327454
			// } else if (this.getOrgInfo && this.getOrgInfo.id && this.getOrgInfo.id === '99ae6853-c620-4ae1-8bb5-d2d3cac66bb4') {
			// 	//Александрия
			// 	return 327454
			} else {
				return false
			}
		},

    },
    mounted() {
        this.getInfo();
    },
    methods: {
        dateReturnFormat(date) {
            return dateReturnFormat(date);
        },
        async getInfo() {
            this.loading = true;
            try {
                this.announcement = await this.$store.dispatch("getAnnouncement");
                this.about = await this.$store.dispatch("getAbout", 0);
            }
            catch (e) {
                this.error = this.$errorsMod(e);
            }
            this.loading = false;
        },
    },
    components: { Banner }
}
</script>


<style>
	.color-advent-white {
		background: #FFF;
		color: #000;
	}
	.color-advent-yellow {
		background: #e6cc62 !important;
		color: #000 !important;
	}
	.color-advent-gray {
		background: #757575 !important;
		color: #fff !important;
	}
	.color-advent-blue {
		background: #5b90e1 !important;
		color: #fff !important;
	}

	.entry-block__title {
		margin-bottom: 20px;
		color: #F90;
	}

	.entry-block__list {
		margin-left: 15px;
		color: #2B2B2B;
	}

	.entry-block__list li {
		list-style: disc;
		padding: 5px 0;
	}

	.corona:hover {
		opacity: 0.7;
		background: #ca2424;
		color: #FFF !important;
	}
</style>
