<template>
    <div>
        <dialogBlock v-model="value" :width="650" position="absolute" left="-50%">
            <template slot="activator">
                <div></div>
            </template>
            <div slot="header">
                <div class="modal-header">Выберите организацию</div>
            </div>
            <div slot="body">
                <div class="modal-body">
                    <div class="modal-body-list">

                        <div class="modal-body-list-item" v-for="(item, index) in organizationList" :key="index" @click="loginOrganization(index)">
                            <div class="stat_icon _building"><i class="fas fa-city"></i></div>
                            <div class="modal-body-list-item-info">
                                <div>{{ item.name }}</div>
                                <div><span>ИНН: </span>{{ item.inn }}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </dialogBlock>
    </div>
</template>

<script>
import dialogBlock from '@/components/modal/modal-global'

export default {
    data() {
        return {
            value: true,
            loading: false,
            error: '',
            login: ''
        }
    },
    components: {
        dialogBlock
    },
    props: ['organizationList', 'clientLogin', 'model'],
    methods: {
        async loginOrganization(index) {

             let token = await this.$store.dispatch('accountAuthorize', {
                userName: this.clientLogin.userName,
                token: this.clientLogin.token,
                organizationId: this.organizationList[index].guid
            })

            let dateExp = new Date() / 1000  +  (60 * 60 * 24 * 30, 60 * 60 * 24 * 30)
            window.$cookies.set("time_token_die_adminOrg", dateExp, 60 * 60 * 24 * 30, null, process.env.coockieUrl)

            window.location.replace(process.env.VUE_APP_REDIRECT_URL_ORG)

        }
    },
    watch: {
        value(val) {
            if(!val) {
                this.$emit('closeModalOrganization')
            }
        },
        model(val) {
            if(val) {
                this.value = true
            }
            
        }
    },
}
</script>

<style lang="sass">
    .modal-body-list
        display: flex
        flex-direction: column
        &-item
            &-info
                text-align: left
                height: 65px
                border-bottom: 1px solid #CCC
                margin: 0px 0
                font-size: 15px
                padding: 10px
                cursor: pointer
                div:nth-child(1)
                    margin: 0 0 7px 0
                    font-size: 16px
                div:nth-child(2)
                    margin: 0 0 5px 0
                    font-size: 13px
                    color: #515151
            &:hover
                background: #e9e9e9
            .stat_icon
                float: left
                width: 40px
                height: 50px
                padding: 20px 0px
                font-size: 24px
                margin: 0 10px 0 0

</style>
