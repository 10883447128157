<template>
	<section class="main" :style="imgBackground">
		<BlockMenu />
		<div class="wrapper">
			<div class="main-descr">
				<img width="100px" :src="logo" alt="" />
				<h1 class="main-descr__title">
					{{ getOrgInfo.organizationName }}
				</h1>
			</div>
		</div>
		<div class="main-bottom">
			<div class="wrapper">
				<div class="main-bottom__left">
					<ul>
						<li>
							<span><i class="fas fa-phone"></i></span>
							<a :href="'tel:' + getOrgInfo.phone">{{
								getOrgInfo.phone
							}}</a>
						</li>
						<li>
							<span><i class="fas fa-map-marker-alt"></i></span>
							<p>{{ getOrgInfo.address }}</p>
						</li>
					</ul>
				</div>
				<div class="main-bottom__right">
					<!-- <modalLogin /> -->
					<div>
						<a
							target="_blank"
							href="https://квартплата.онлайн/login"
							class="main-office"
							>личный кабинет</a
						>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import BlockMenu from "@/components/template/menu.vue";
import modalLogin from "@/components/modal/modal-login";
import { mapGetters } from "vuex";

export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters(["getOrgInfo", "getOrgSettings"]),
		imgBackground() {
			if (this.getOrgSettings.backgroundImageName) {
				return `background: url(${process.env.VUE_APP_URL_LOCAL_FILES}/${this.getOrgSettings.backgroundImageName});`;
			} else if (!this.getOrgSettings.backgroundImageName) {
				return `background: url(/img/banner-bg-2.jpg);`;
			}
		},
		logo() {
			if (this.getOrgSettings.logoImageName) {
				return `${process.env.VUE_APP_URL_LOCAL_FILES}/${this.getOrgSettings.logoImageName}`;
			} else if (!this.getOrgSettings.logoImageName) {
				return `/img/header-logo.png`;
			}
		}
	},

	beforeUpdate() {
		//console.log('TEST beforeUpdate', this.getOrgSettings, this.getLoadingPage)
	},

	beforeMount() {
		//console.log('TEST beforeMount', this.getOrgSettings)
	},

	mounted() {
		//console.log('TEST Mounted', this.getOrgSettings)
	},

	beforeCreate() {
		//console.log('TEST beforeCreate', this.getOrgSettings)
	},

	components: {
		BlockMenu,
		modalLogin
	}
};
</script>
