<template>
    <div>
        <dialogBlock v-model="value">
            <template slot="activator">
                <a class="gl_link popupForm main-bottom__confirm"  @click="openModal()">Подтвердить ?</a>
            </template>
            <div slot="header">
                <div class="modal-header">Подтверждение учетной записи</div>
            </div>
            <div slot="body">
                <!-- Окно подтверждения -->
                <div class="modal-body" v-if="!tryBlock">
                    На указанный {{ confirmMail ? 'e-mail' : '' }} {{ confirmPhone ? 'телефон' : '' }}  отправлено сообщение. Введите код ниже, для подтверждения учетной записи.
                    <form action="#">
                        <appInput 
                            v-model="$v.confirmCode.$model"
                            :error="$v.confirmCode.$error"
                            type="text"
                            placeholder="Код"
                        />

                        <div class="form_cell">
                            <button class="btn_v1 login_btn btn_orange" @click.prevent="confirmLogin">
                                <span v-if="!loading">Подтвердить</span>
                                <loading v-else />
                            </button>
                        </div>
                        <span class="span_v1 error" v-for="(error, index) in error" :key="index"> {{ error.message }} </span>
                        <span class="span_v1 error" v-if="errorValidClient"> {{ errorValidClient }} </span>
                    </form>
                </div>
                <!-- Окно успех -->
                <div class="modal-body" v-if="tryBlock">
                    <div  style="color: green; font-size: 15px;">
                        Логин успешно подтвержден!
                        <br><br>
                    </div>
                </div>

            </div>
        </dialogBlock>
    </div>
</template>

<script>
import dialogBlock from '@/components/modal/modal-global'
import loading from '@/components/loading'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
        return {
            value: false,
            confirmCode: '',
            loading: false,
            error: [],
            errorValidClient: '',
            confirmPhone: false,
            confirmMail: false,
            register: {},
            tryBlock: false,
            form: {
                password: '',
                confirmPassword: '',
                login: '',
            },
        };
    },
    props: {
        login: {
            type: String,
            default: ''
        },
        password: {
            type: String,
            default: ''
        },
    },
    mounted() {

    },
    methods: {
        async confirmLogin() {
            this.error = []
            this.errorValidClient = ''
            this.$v.confirmCode.$touch()

            if(!this.$v.confirmCode.$invalid) {
                try {
                    let query = await this.$store.dispatch('confirmLogin', {
                        login: this.login,
                        code: this.confirmCode
                    })
                    this.tryBlock = true
                } catch(e) {
                    this.error = this.$ErrorsMode(e)
                }
            } else {
                this.errorValidClient = 'Заполните все поля'
            }
        },
        async openModal() {

            let phonePattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
            let emailPattern = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/

            this.confirmPhone = phonePattern.test(this.login)
            this.confirmMail = emailPattern.test(this.login)

            try {
                await this.$store.dispatch('sendConfirmLogin', {
                    login: this.login
                })
                this.value = true
                this.error = []
                this.confirmCode = ''
                this.form.login = ''
                this.form.password = ''
                this.form.confirmPassword = ''
                this.tryBlock = false
            } catch(e) {
                this.error = this.$ErrorsMode(e)
            }
        }

    },
    components: {
        dialogBlock, loading
    },
    watch: {
        value(val) {
            if(!val && this.tryBlock) {
                this.$emit('resetError')
                this.$store.commit('mutLoginNoConfirmed', false)
            }
        }
    },
    validations: {
        confirmCode: {
            required
        }
    }
};
</script>

<style scoped>
.main-bottom__confirm {
    font-size: 16px;
    color: #4394e6;
    text-transform: uppercase;
    max-width: 275px;
    max-height: 55px;
    background:#fff;
    height: 100%;
    display: block;
    padding: 5px 5px;
    width: 100%;
    text-align: center;
    border: 1px solid#4394E6;
    margin: 5px 0 20px 0;
    cursor: pointer;
}
</style>