<template>
	<footer class="footer">
		<div class="wrapper">
			<div class="footer-logo">
				<a href="#"
					><img src="@/img/logo_dark.png" alt="" width="120px"
				/></a>
			</div>

			<div class="footer-text">
				<a
					v-if="
						getOrgInfo &&
							getOrgInfo.id ===
								'cca1e2c1-a68d-e911-a9c5-38d547b912d4'
					"
					target="_blank"
					href="/files/1.pdf"
					class="file-link"
				>
					Политика обработки персональных данных
				</a>
				<div class="veriosn-buttton" @click="versionSite()">
					<i class="fas fa-glasses"></i>
					{{
						versionGlasses
							? "Обычная версия"
							: "Версия для слабовидящих"
					}}
				</div>
				<p>©2011-{{ date }} ООО "ЖилФондСервис" Все права защищены</p>
			</div>
			<div class="footer-app">
				<a
					target="_blank"
					href="https://play.google.com/store/apps/details?id=com.myhousereactnativemigration&hl=ru"
					><img src="@/img/goole-play.png" alt=""
				/></a>
				<a
					target="_blank"
					href="https://apps.apple.com/ru/app/%D0%BA%D0%B2%D0%B0%D1%80%D1%82%D0%BF%D0%BB%D0%B0%D1%82%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1482895258"
					><img src="@/img/app-store.png" alt=""
				/></a>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			date: new Date().getFullYear(),
			versionGlasses: false
		};
	},
	props: {
		template: {
			type: String,
			default: "template_1"
		}
	},
	computed: {
		...mapGetters(["getOrgInfo"])
	},
	mounted() {
		let version = window.$cookies.get("version_site");

		if (version === "1") {
			this.versionGlasses = true;
		}
	},
	methods: {
		versionSite() {
			if (this.versionGlasses == false) {
				this.versionGlasses = true;
				window.$cookies.set(
					"version_site",
					1,
					null,
					null,
					process.env.coockieUrl
				);
				document
					.getElementById("app")
					.classList.add("version-switcher");
			} else {
				this.versionGlasses = false;
				window.$cookies.remove(
					"version_site",
					"/",
					process.env.coockieUrl
				);
				document
					.getElementById("app")
					.classList.remove("version-switcher");
			}
		}
	},
	components: {}
};
</script>

<style scoped>
.veriosn-buttton {
	cursor: pointer;
	color: #fff;
	text-align: center;
	margin: 20px 0;
	font-size: 20px;
}

.veriosn-buttton i {
	margin-right: 10px;
}
.file-link {
	display: block;
	color: #fff;
	text-align: center;
	text-decoration: underline;
}
</style>
