<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getAbout"
    >
		<div slot=body>
			<div class="Main">
				<section class="content">
					<div class="wrapper">
						<div class="news-content">
							<h2 class="news-content__title">О нас</h2>
							<span class="ql-editor" v-html="about"></span>  <!-- style="display: table" -->
						</div>
					</div>
				</section>
			</div>
		</div>
    </appGloballayout>
</template>

<script>

export default {
	name: 'index',
	data() {
		return {
			about: {},
			loading: true,
			error: false
		}
	},
	mounted() {
		this.getAbout()
	},
	methods: {
		async getAbout() {
			this.loading = true
			try {
				this.about = await this.$store.dispatch('getAbout', 1);
			} catch(e) {
				this.error = this.$errorsMod(e)
			}
			this.loading = false
		}
	}
}
</script>
