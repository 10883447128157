<template>
	<section class="main">
		
		<div class="main-top" :style="imgBackground">
			<div class="wrapper">
				
				<ul class="main-info">
					<li>
						<span><i class="fas fa-phone"></i></span>
						<a :href="'tel:'+getOrgInfo.phone">
							{{ getOrgInfo.phone }}
						</a>
					</li>
					<li>
						<span><i class="fas fa-map-marker-alt"></i></span>
						{{ getOrgInfo.address }}
					</li>
				</ul>
				<div class="main-logo">
					<img width="100px" :src="logo" alt="">
				</div>
				<!-- <modalLogin /> -->
				<div>
					<a target="_blank" href="https://квартплата.онлайн/login" class="main-office">личный кабинет</a>
				</div>
			</div>
		</div>
		<div class="main-bottom">
			<div class="wrapper">
				<h1 class="main-title">{{ getOrgInfo.organizationName }}</h1>
			</div>
			<BlockMenu />
		</div>
	</section>
</template>



<script>
	import BlockMenu from '@/components/template/menu.vue'
	import modalLogin from '@/components/modal/modal-login'
	import { mapGetters } from 'vuex'

	export default {
		data() {
			return {

			}
		},
        computed: {
			...mapGetters([
				'getOrgInfo', 'getOrgSettings'
			]),
			imgBackground() {
				if(this.getOrgSettings.backgroundImageName) {
					return `background: url(${process.env.VUE_APP_URL_LOCAL_FILES}/${this.getOrgSettings.backgroundImageName});`
				} else {
					return  `background: url(/img/banner-bg-2.jpg);`
				}
			},
			logo() {
				if(this.getOrgSettings.logoImageName) {
					return `${process.env.VUE_APP_URL_LOCAL_FILES}/${this.getOrgSettings.logoImageName}`
				} else {
					return  `/img/header-logo.png`
				}
			}
        },
		components: {
			BlockMenu, modalLogin
		}
	}
</script>


