<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getNewsPagination"
    >
		<section class="content" slot=body>
			<div class="wrapper">
				<h2 class="content-title">Новости</h2>
				<div style="display: flex; justify-content: flex-end;">
					<div></div>
				</div>
				<div class="news-row">
					<template v-if="dataNews.models.length > 0">
						<div class="news-column" v-for="(item, index) in dataNews.models" :key="index">
							<h3 class="news-column__title"><span>{{ dateReturnFormat(item.date) }}</span>{{ item.title }}</h3>
							<p>{{item.shortText }}</p>
							<router-link :to="'/news/'+item.id">Читать полностью</router-link>
						</div><!-- col(1) -->
					</template>
					<template v-else>
						Новости отсутствуют
					</template>
					<v-pagination
						v-if="dataNews.totalPages > 1"
						v-model="currentPage"
						:page-count="dataNews.totalPages"
						:classes="classes"
						:labels="paginationAnchorTexts">
					</v-pagination>
				</div>
			</div>
		</section>
    </appGloballayout>
</template>

<script>
import vPagination from 'vue-plain-pagination'
import { dateReturnFormat } from '@/plugins/methods'

export default {
    data() {
        return {
            dataNews: {
				models: [],
				pageSize: 10,
				page: 1,
				totalPages: 1,
			},
            currentPage: 1,
            totalPages: 1,
            title: 'Новости',
            paginationAnchorTexts: {
                //first: 'Первая',
                prev: 'Назад',
                next: 'Вперед',
                //last: 'Последняя'
            },
            classes: {
                ul: 'pagination',
                li: 'page-item',
                liActive: 'active',
                liDisable: 'disabled',
                button: 'page-link'  
            },
			loading: true,
			error: false
        }
    },
    async mounted() {
        this.getNewsPagination(1)
    },
    methods: {
        async getNewsPagination(index) {
			this.loading = true
            try {
                this.dataNews = await this.$store.dispatch('getNewsPagination', {
					page: index
				})
            } catch(e) {
                this.error = this.$errorsMod(e)
            }

			setTimeout(() => {
				this.loading = false
			}, 200);
			
        },
		dateReturnFormat(date) {
			return dateReturnFormat(date)
		}
    },
    components: {
		vPagination
	},
	watch: {
		currentPage(val) {
			this.getNewsPagination(val)
		}
	}
}
</script>

<style>
	.pagination {
		display: -ms-flexbox;
		display: flex;
		padding-left: 0;
		list-style: none;
		border-radius: .25rem;
		justify-content: center;
	}
	.page-item.disabled .page-link {
		color: #6c757d;
		pointer-events: none;
		cursor: auto;
		background-color:#fff;
		border-color: #dee2e6;
	}
	.page-link {
		font-size: 14px;
		position: relative;
		display: block;
		padding: .5rem .75rem;
		margin-left: -1px;
		line-height: 1.25;
		color: #007bff;
		background-color: #fff;
		border: 1px solid #dee2e6;
	}
	.page-link:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
	.page-item.active .page-link {
		z-index: 1;
		color: #fff;
		background-color:#007bff;
		border-color:#007bff;
	}
</style>