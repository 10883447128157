<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getDocuments"
    >
		<section class="content"  slot=body>
			<div class="wrapper">
				<h2 class="content-title">Документы</h2>
				<tree :itemsTree="treeDataFile" />
				<div class="documentation-row"></div>
			</div>
		</section>
    </appGloballayout>
</template>

<script>
import tree from "@/components/tree/tree";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			treeDataFile: {
				label: "root",
				isFolder: true,
				children: []
			},
			title: "Документы",
			loading: true,
			error: false
		};
	},
	components: {
		tree
	},

    async mounted() {
        this.getDocuments()
    },

	methods: {
		async getDocuments() {
			this.loading = true
			try {
				this.treeDataFile.children = await this.$store.dispatch('getDocuments')
			} catch(e) {
				this.error = this.$errorsMod(e)
			}
			this.loading = false
		}
    },

	computed: {

	}
};
</script>
