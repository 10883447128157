<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getNewsInfo"
    >
		<section class="content"  slot=body>
			<div class="wrapper">
				<div class="news-column-full">
					<h1>{{ title }}</h1>
					<p>
						<span v-html="text">{{text}}</span>
					</p>
					<p v-if="url">
						<br>
						<b>Источник: </b> <a target="_blank" style="color: blue" :href="url">{{ url }}</a>
					</p>
				
					<div v-if="documents.length" class="file-list">
						<h3>Файлы для скачивания</h3>
						<div v-for="(item, index) in documents" :key="index" class="file-list-item">
							<i class="fas fa-folder folder file-icon" v-if="item.isFolder"></i>
							<i class="fas fa-file-pdf pdf file-icon" v-else-if="item.extension == '.pdf'"></i>
							<i class="fas fa-file-alt alt file-icon" v-else-if="item.extension == '.txt'"></i>
							<i class="fas fa-file-excel excel file-icon" v-else-if="item.extension == '.xls'"></i>
							<i class="fas fa-file-excel excel file-icon" v-else-if="item.extension == '.xlsx'"></i>
							<i class="fas fa-file-word  word file-icon" v-else-if="item.extension == '.docx'"></i>
							<i class="fas fa-file-archive archive file-icon" v-else-if="item.extension == '.zip'"></i>
							<i class="fas fa-file-archive archive-rar file-icon" v-else-if="item.extension == '.rar'"></i>
							<i class="fas fa-file-image image file-icon" v-else-if="item.extension == '.png'"></i>
							<i class="fas fa-file-image image file-icon" v-else-if="item.extension == '.jpeg'"></i>
							<i class="fas fa-file-image image file-icon" v-else-if="item.extension == '.jpg'"></i>
							<i class="fas fa-file-file file file-icon" v-else></i>
							<a rel="nofollow" :href="baseUrl+item.fileName">{{ item.title }}</a>
						</div>
					</div>
				</div>
			</div>
		</section>
    </appGloballayout>
</template>

<script>
export default {
    data() {
        return {
            text: '',
            url: '',
            title: '',
            documents: '',
            baseUrl: process.env.VUE_APP_URL+'/organization/docs/',
			date: '',
			loading: true,
			error: false
        }
    },
	mounted() {
		this.getNewsInfo()
	},

	methods: {
		async getNewsInfo() {
			this.loading = true
			try {
				let query = await this.$store.dispatch('getNews',  this.$route.params.id)

				this.date = query.data
				this.text = query.text
				this.title = query.title
				this.url = query.url
				this.documents = query.documents

			} catch(e) {
				this.error = this.$errorsMod(e)
			}

			this.loading = false
		}
	},

	computed: {

	},
}
</script>

<style scoped>
	.file-list {
		margin-top: 30px;
	}

	.file-list-item {
		margin: 10px;
	}

	.file-list-item a {
		color: blue;
	}

	.file-list-item i {
		font-size: 30px;
		margin: 10px 20px 0 0;
	}

	.pdf {
		font-size: 20px; 
		color: rgb(255, 97, 91);
	}

	.alt {
		font-size: 20px; 
		color: #6baafd;
	}
	.word {
		font-size: 20px; 
		color: #2a5495;
	}

	.excel {
		font-size: 20px; 
		color: rgb(62, 172, 12);
	}
	.archive {
		font-size: 20px; 
		color: #8dd21a;
	}
	.archive-rar {
		font-size: 20px; 
		color: #b65bab;
	}

	.image {
		font-size: 20px; 
		color: #313131;
	}

	.file {
		font-size: 20px; 
		color: #CCC;
	}
</style>