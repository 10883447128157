<template>
    <div>
        <headerTemp1 v-if="getOrgSettings.template == 'template-1' || !getOrgSettings.template" />
        <headerTemp2 v-if="getOrgSettings.template == 'template-2'" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import headerTemp1 from './header-temp1'
import headerTemp2 from './header-temp2'

export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters([
            'getOrgSettings'
        ])
    },
    components: {
        headerTemp1, headerTemp2
    }
}
</script>