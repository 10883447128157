export function dateReturnFormat (getDateVal, hour) {
	if (hour) {
		let getDate = new Date(getDateVal)
		let newDate = getDateVal == null
			? ''
			: (getDate.getDate() < 10 ? '0' : '') + getDate.getDate() + '.' + ((getDate.getMonth() + 1) < 10 ? '0' : '') + (getDate.getMonth() + 1) + '.' + getDate.getFullYear() + ' (' + getDate.getHours() + ':' + (getDate.getMinutes() < 10 ? '0' : '') + getDate.getMinutes() + ')'
		return newDate
	} else {
		let getDate = new Date(getDateVal)
		let newDate = getDateVal == null
			? ''
			: (getDate.getDate() < 10 ? '0' : '') + getDate.getDate() + '.' + ((getDate.getMonth() + 1) < 10 ? '0' : '') + (getDate.getMonth() + 1) + '.' + getDate.getFullYear()
		return newDate
	}
}

export function getPhone (phone) {
	if (phone == undefined) return ''
	let arrphone = phone.split('')

	if (arrphone.length == 11) {
		return `${arrphone[0]}(${arrphone[1]}${arrphone[2]}${arrphone[3]}) ${arrphone[4]}${arrphone[5]}${arrphone[6]} ${arrphone[7]}${arrphone[8]} ${arrphone[9]}${arrphone[10]}`
	} else {
		return phone
	}
}

export function getNumber (number) {
	if (isFinite(value) && value === parseInt(value, 10)) {
		return number.toFixed(2)
	} else {
		return '0.0'
	}
}

export function digitNumber (number) {
	let digitNumber = (number+ '').split('.')
	return (number >= 0 ? '' : '-') + digitNumber[0].toString().replace(/[^\d]/g, '').replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ') + (digitNumber[1] ? '.' + digitNumber[1] : '') 
}
