import Vue from "vue";
import VueRouter from "vue-router";

import Main from "@/views/main";
import News from "@/views/news";
import NewsId from "@/views/news/id";
import Documents from "@/views/documents";
import Vacancies from "@/views/vacancies";
import Contacts from "@/views/contacts";
import About from "@/views/about";

import ErrorLayout from "@/views/error";

Vue.use(VueRouter);

const routes = [
	{
		path: '',
		redirect: { name: 'Main' }
	},
	{
		path: "/",
		name: "Main",
		component: Main
	},
	{
		path: "/about",
		name: "About",
		component: About
	},
	{
		path: "/news",
		name: "News",
		component: News
	},
	{
		path: "/news/:id",
		name: "news",
		component: NewsId,
	},
	{
		path: "/documents",
		name: "documents",
		component: Documents,
	},
	{
		path: "/vacancies",
		name: "vacancies",
		component: Vacancies,
	},
	{
		path: "/contacts",
		name: "contacts",
		component: Contacts,
	},
	{
		path: '*',
		redirect: '/'
	},
	{
		path: '/404',
		name: '404',
		mode: 'history',
		component: ErrorLayout
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

export default router;
