<template>
	<div class="__nuxt-error-page">
		<div class="error-block">
			<div class="title">Ошибка</div>
			
			<p class="description">
				Сайт заблокирован. По всем вопросам обращайтесь в вашу организацию. 
			</p>

			<div style="margin-top: 20px">
				Для входа в Личный кабинет используйте сервис <a style="color: #0064ff !important;font-weight: bold;" href="https://квартплата.онлайн/">"Квартплата.онлайн"</a>
				
				<a href="https://квартплата.онлайн/login" class="blocked-page__entry">Войти</a>
			</div>

		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
	props: {
        error: {
            type: Object,
            default: () => ({
                text: 'Ошибка',
                statusCode: 404
            })
        }
    },
	computed: {

	},
    mounted() {
        console.log('ERROR PAGE', this.$router)
    }
}
</script>

<style scoped>
	.footer {
		display: none !important;
	}
</style>

<style>
	.blocked-page__entry {
		margin: 30px auto;
		padding: 10px 15px;
		background: #3795F9;
		width: 150px;
		border-radius: 3px;
		font-size: 15px;
		color: #FFF !important;
		font-weight: bold;
		display: block;
	}

	.blocked-page__entry:hover {
		opacity: 0.7;
	}


	.__nuxt-error-page {
		padding: 1rem;
		background: #F7F8FB;
		color: #47494E;
		text-align: center;
		display: flex;
		align-items: center;
		flex-direction: column;
		font-family: sans-serif;
		font-weight: 100 !important;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 300;
		overflow: hidden;
	}

	.error-block:before{
		z-index:-1;
		position:absolute;
		bottom: 0;
		left: 0;
		right: 0;
		content: url('/img/glance-smart-city.png');
		opacity:0.2;
	}

	.__nuxt-error-page .error {
		max-width: 450px;
	}

	.__nuxt-error-page .title {
		font-size: 1.5rem;
		margin-top: 115px;
		color: #47494E;
		margin-bottom: 8px;
	}
	.__nuxt-error-page .description {
		color: #7F828B;
		line-height: 21px;
		margin-bottom: 10px;
	}
	.__nuxt-error-page a {
		text-decoration: none;
	}
	.__nuxt-error-page .logo {
		position: fixed;
		left: 12px;
		bottom: 12px;
	}


	.version-switcher .__nuxt-error-page .title {
		color: #000000;
		font: bold 46px/1.4 Arial,sans-serif;
	}

	.version-switcher .__nuxt-error-page .description {
		color: #000000;
		font: bold 130%/1.4 Arial,sans-serif;
	}

	.version-switcher .__nuxt-error-page .error-block a span {
		color: #000000 !important;
		font: bold 130%/1.4 Arial,sans-serif;
	}
</style>