<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getContacts"
    >
		<section class="content" slot=body>
			<div class="wrapper">
				<h2 class="content-title">КОНТАКТЫ</h2>
				<div class="contact-row">
					<div class="contact-column">
						<h3 class="contact-column__title">Данные по организации</h3>
						<ul>
							<li>Телефон: <span>{{ companyInfo.phone }}</span></li>
							<li>Email: <span> <a :href="'mailto:'+companyInfo.email">{{ companyInfo.email }} </a></span></li>
							<li>Адрес: <span>{{ companyInfo.address }}</span></li>
							<li>Приёмные часы: <a>{{ companyInfo.workingTime }}</a></li>

							<li>ИНН: <span>{{ companyInfo.inn }}</span></li>
							<li>ОГРН: <span>{{ companyInfo.ogrn }}</span></li>

							<li>Банк: <span>{{ companyInfo.bankName }}</span></li>
							<li>Расчётный счёт: <span>{{ companyInfo.rs }}</span></li>
							<li>БИК: <span>{{ companyInfo.bik }}</span></li>
							<li>Корр. счёт: <span>{{ companyInfo.ks }}</span></li>
							<li>Руководитель: <span>{{ companyInfo.director }}</span></li>
						</ul>
					</div><!-- col(1) -->
					<div class="contact-column">
						<h3 class="contact-column__title">Данные по сотрудникам организации</h3>
						<ul class="contact-list__items">
							<li v-for="(item, index) in contactList" :key="index" class="contact-list__item">
								<p v-if="item.contactName" class="contact-list__text">{{ item.contactName }}</p>
								<p v-if="item.position" class="contact-list__text">{{ item.position }}</p>
								<a :href="`tel:${item.phone}`" v-if="item.phone" class="contact-list__link bold">{{ item.phone }}</a>
								<p v-if="item.receptionHours" class="contact-list__text bold">{{ item.receptionHours }}</p>
								<p v-if="item.description" class="contact-list__text">{{ item.description }}</p>
								<!-- {{item.position}} - {{item.contactName}}
								<span><a :href="'tel:'+item.phone.replace(/\s/g, '')">{{item.phone}}</a> - {{ item.receptionHours }}</span> -->
							</li>
						</ul>
					</div><!-- col(2) -->
				</div>
			</div>
		</section>
    </appGloballayout>
</template>

<script>
export default {
    data() {
        return {
			companyInfo: {},
			contactList: [],
			error: false,
			loading: true
        }
    },

    async mounted() {
		this.getContacts()
    },
	methods: {
		async getContacts(){
			this.loading = true
			try {
				this.companyInfo = await this.$store.dispatch('getGetInfo');
            	this.contactList = await this.$store.dispatch('getListContacts');
			} catch(e) {
				this.error = this.$errorsMod(e)
			}
			setTimeout(() => {
				this.loading = false
			}, 200);
		}
	}

}
</script>

<style scoped>
.contact-list__items {
	display: flex;
	flex-direction: column !important;
	gap: 32px !important;
}

.contact-list__item {
	display: flex;
	flex-direction: column !important;
	gap: 8px !important;
	margin: 0 !important;
}

.contact-list__text {
	font-family: Source Sans Pro;
	font-size: 16px;
	font-weight: 400;
	line-height: 19.2px;
	color: #000000;
}

.contact-list__link {
	color: #3366FF;
	text-decoration: none;
	cursor: pointer;
	font-family: Source Sans Pro;
	font-size: 16px;
	font-weight: 700;
	line-height: 19.2px;
}

.bold {
	font-weight: 700;
}
</style>