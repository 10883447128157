import {$axiosAuth} from '@/plugins/axios-instance'

export default {
	state: {

	},
	actions: {
        registrUser(context, data) {
            //console.log(data, process.env.baseUrl)

            return $axiosAuth.post('/Account/Register', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })

        },

        accountAuthorize(context, data) {

            return $axiosAuth.post('/Account/Authorize', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })

        },

        sendResetPasswordCode(context, data) {

            return $axiosAuth.post('/Account/SendResetPasswordCode', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })

        },

        resetPassword(context, data) {

            return $axiosAuth.post('/Account/ResetPassword', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })

        },

        confirmLogin(context, data) {
            return $axiosAuth.post('/Account/confirmLogin', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })
        },

        sendConfirmLogin(context, data) {
            return $axiosAuth.post('/Account/SendConfirmLogin', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })
        },

        login(context, data) {

            return $axiosAuth.post('/Account/Login', data)
            .then((response) => {
                return response.data
            }).catch((e)=>{
                throw(e.response)
            })
        }



        
    }
}
