<template>
    <li>
        <div
            :class="!isFolder ? 'noFolder' : 'folder'"
            @click="toggle"
            v-show="item.label != 'root'"
            class="menu-block"
            >
            <i class="icon-folder" :class="isOpen || item.label == 'root' ? 'fas fa-folder-open' :'fas fa-folder'" v-if="isFolder"></i>

            <template v-if="!isFolder">
					<i class="fas fa-folder folder file-icon" v-if="item.isFolder"></i>
					<i class="fas fa-file-pdf pdf file-icon" v-else-if="item.file == '.pdf'"></i>
					<i class="fas fa-file-alt alt file-icon" v-else-if="item.file == '.txt'"></i>
					<i class="fas fa-file-excel excel file-icon" v-else-if="item.file == '.xls'"></i>
					<i class="fas fa-file-excel excel file-icon" v-else-if="item.file == '.xlsx'"></i>
					<i class="fas fa-file-word  word file-icon" v-else-if="item.file == '.docx'"></i>
					<i class="fas fa-file-word  word file-icon" v-else-if="item.file == '.doc'"></i>
					<i class="fas fa-file-word  word file-icon" v-else-if="item.file == '.odt'"></i>
					<i class="fas fa-file-archive archive file-icon" v-else-if="item.file == '.zip'"></i>
					<i class="fas fa-file-archive archive-rar file-icon" v-else-if="item.file == '.rar'"></i>
					<i class="fas fa-file-image image file-icon" v-else-if="item.file == '.png'"></i>
					<i class="fas fa-file-image image file-icon" v-else-if="item.file == '.jpeg'"></i>
					<i class="fas fa-file-image image file-icon" v-else-if="item.file == '.jpg'"></i>
					<i class="fas fa-file-file file file-icon" v-else></i>
            </template>
            <a rel="nofollow" :href="isFolder ? '#' : url+item.fileName" :target="isFolder? '_self' : '_blank' ">{{ item.label }}</a>
            <a target="_blank" rel="nofollow" v-if="!isFolder" :href="url+item.fileName"><i class="fas fa-cloud-download-alt download-icon" ></i></a>

            <i class="icon-folder" v-if="isFolder" :class="isOpen ? 'fas fa-arrow-down' : 'fas fa-arrow-up'" ></i>
            <span v-if="isFolder">[{{ isOpen ? '-' : '+' }}]</span>
        </div>
        <transition name="fade">
            <ul v-show="isOpen  || item.label == 'root'" v-if="isFolder">
                <treeItem
                    class="item"
                    v-for="(child, index) in item.children"
                    :key="index"
                    :item="child"
                    
                   
                ></treeItem>
            
            </ul>
        </transition>
    </li>
</template>

<script>
export default {
    name: 'treeItem',
    data() {
        return {
            name: '',
            isOpen: false,
            url: process.env.VUE_APP_URL+'/organization/docs/',
        }
    },
    props: {
        item: Object
    },
    computed: {
        isFolder () {
            return this.item.isFolder
        }
    },
    methods: {
        toggle () {
            if (this.isFolder) {
                this.isOpen = !this.isOpen
            }
        },
    }
}
</script>

<style>
    .menu-block {
        background: #CCC;
        margin: 10px 0;
        font-size: 18px;
        position: relative;
        background: #f5f5f5;
        padding: 12px 20px 11px 55px;
        height: auto;
        cursor: default;
        color: #000;
    }
    .noFolder {
        background: #f7f7f7;
        color: #000;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
    .download-icon {
        right: 15 !important;
        cursor: pointer;
        position: relative;
        z-index: 100;
    }

    .folder {
        cursor: pointer;
    }



	.pdf {
		font-size: 20px; 
		color: rgb(255, 97, 91);
	}

	.alt {
		font-size: 20px; 
		color: #6baafd;
	}
	.word {
		font-size: 20px; 
		color: #2a5495;
	}

	.excel {
		font-size: 20px; 
		color: rgb(62, 172, 12);
	}
	.archive {
		font-size: 20px; 
		color: #8dd21a;
	}
	.archive-rar {
		font-size: 20px; 
		color: #b65bab;
	}

	.image {
		font-size: 20px; 
		color: #313131;
	}

	.file {
		font-size: 20px; 
		color: #CCC;
	}

	.tree-folder {
		background: #EAEAEA;
		width: 100%;
		line-height: 40px;
		padding-left: 10px;
		border-radius: 3px
	}

    @media (max-width: 900px) {
        .menu-block {
            font-size: 14px;
            height: auto;
        }
    }

</style>