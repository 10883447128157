import Vue from "vue";
import Vuex from "vuex";
import $axios from "@/plugins/axios-instance";
import modal from "./modal";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		modal
	},
	state: {
		announcement: {},
		orgSettings: {
			template: "template-1",
			backgroundImageName: null,
			logoImageName: null
		},
		orgInfo: {},
		loginNoConfirmed: false,
		isMounted: false,
		organizationBlocked: false
	},
	mutations: {
		mutLoginNoConfirmed(state, error) {
			state.loginNoConfirmed = error;
		},
		organizationBlocked(state, data) {
			state.organizationBlocked = data;
		}
	},
	getters: {
		getOrgInfo(state) {
			return state.orgInfo;
		},
		getIsMounted(state) {
			return state.isMounted;
		},

		getOrgSettings(state) {
			return state.orgSettings;
		},

		getLoginNoConfirmed(state) {
			return state.loginNoConfirmed;
		},

		getOrganizationBlocked(state) {
			return state.organizationBlocked;
		}
	},
	actions: {
		getAnnouncement(context, data) {
			return $axios
				.get(`/organization/announce`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getAbout(context, type) {
			return $axios
				.get(`/organization?PageType=${type}`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getSettings(context, data) {
			return $axios
				.get(`/organization/settings`)
				.then(response => {
					context.state.orgSettings = response.data;

					if (
						response.data.template == "template-1" ||
						!response.data.template
					) {
						document
							.getElementById("app")
							.classList.add("template-one");
					} else if (response.data.template == "template-2") {
						document
							.getElementById("app")
							.classList.remove("template-one");
						document
							.getElementById("app")
							.classList.add("template-two");
					}

					context.state.isMounted = true;

					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getNewsPagination(context, data) {
			return $axios
				.get(`/organization/news?page=${data.page}&count=10`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getDocuments(context, data) {
			return $axios
				.get(`/organization/docs`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getVacancy(context, data) {
			return $axios
				.get(`/organization/vacancy`)
				.then(response => {
					for (let item of response.data) {
						item.description = item.description.replace(
							/\n/g,
							"<br />"
						);
					}

					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getGetInfo(context, data) {
			return $axios
				.get(`/organization/info`)
				.then(response => {
					context.state.orgInfo = response.data;

					// ООО Атмосфера - просили убрать надпись Квартплата онлайн
					const title =
						response.data.id ===
						"d449a01b-d694-47ca-80c7-4ecb2f48bfbc"
							? response.data.organizationName
							: response.data.organizationName +
							  " | Квартплата.онлайн";

					document.title = title;
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getListContacts(context, data) {
			return $axios
				.get(`/organization/contacts`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		},

		getNews(context, id) {
			return $axios
				.get(`/organization/news/${id}`)
				.then(response => {
					return response.data;
				})
				.catch(e => {
					throw e;
				});
		}
	}
});
