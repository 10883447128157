<template>
    <div>
        <dialogBlock v-model="value">
            <template slot="activator">
                <span class="activator-link" @click="openModal()">Регистрация</span>
            </template>
            <div slot="header">
                <div class="modal-header">Регистрация</div>
            </div>
            <div slot="body">
                <!-- Первичное окно -->
                <div class="modal-body" v-if="!confirmPhone && !confirmMail">
                    <form action="#" @submit.prevent>
                        <appInput 
                            v-model.trim="$v.form.login.$model"
                            :error="$v.form.login.$error"
                            type="text"
                            placeholder="Номер телефона или почта"
                        />
                        <appInput 
                            v-model.trim="$v.form.password.$model"
                            :error="!$v.form.password.required && $v.form.password.$dirty"
                            type="password"
                            placeholder="Пароль"
                        />

                        <appInput 
                            v-model.trim="$v.form.confirmPassword.$model"
                            :error="!this.$v.form.confirmPassword.required && this.$v.form.confirmPassword.$dirty"
                            type="password"
                            placeholder="Повторите пароль"
                        />

                        <div class="form_cell">
                            <button class="btn_v1 login_btn btn_orange" @click.prevent="registerUser">
                                {{ loading ? '' : 'Регистрация' }}
                                <loading v-if="loading" />
                            </button>
                        </div>

                        <span class="span_v1 error" v-if="errorValidClient"> {{ errorValidClient }} </span>
                        <span class="span_v1 error" v-for="(error, index) in error" :key="index"> {{ error.message }} </span>
                    </form>
                </div>
                <!-- Окно подтверждения -->
                <div class="modal-body" v-if="confirmPhone || confirmMail">
                   На указанный {{ confirmMail ? 'emeil' : '' }} {{ confirmPhone ? 'телефон' : '' }}  отправлено сообщение. Введите код ниже, для подтверждения учетной записи.
                    <form action="#"  @submit.prevent>

                        <appInput 
                            v-model="$v.confirmCode.$model"
                            :error="$v.confirmCode.$error"
                            type="text"
                            placeholder="Код"
                        />

                        <div class="form_cell">
                            <button class="btn_v1 login_btn btn_orange" @click.prevent="confirmLogin">Подтвердить</button>
                        </div>
                        <span class="span_v1 error" v-if="errorValidClient"> {{ errorValidClient }} </span>
                        <span class="span_v1 error" v-for="(error, index) in error" :key="index"> {{ error.message }} </span>
                        <div class="reset-code">
                            <span @click="sendConfirmLogin">Отправить повторно</span> через {{ timer }}  сек.
                        </div>
                    </form>
                </div>
            </div>
        </dialogBlock>
    </div>
</template>

<script>
import dialogBlock from '@/components/modal/modal-global'
import loading from '@/components/loading'
import { required, minLength, between, sameAs } from 'vuelidate/lib/validators'

let testPassword = (val) => {
    let test = val ? /\d/.test(val) : true
    return test
}

export default {
  data() {
        return {
            value: false,
            confirmCode: '',
            loading: false,
            loadingConfirm: false,
            error: '',
            errorValidClient: '',
            confirmPhone: false,
            confirmMail: false,
            form: {
                password: '',
                confirmPassword: '',
                login: '',
            },
            timer: 0,
        };
    },
    props: {

    },
    mounted() {

    },
    methods: {
        async registerUser() {
            this.$v.form.login.$touch()
            this.$v.form.password.$touch()
            this.$v.form.confirmPassword.$touch()
            this.error = []
            this.errorValidClient = ''
            if(!this.$v.form.$invalid) {
                try {
                    await this.$store.dispatch('registrUser', this.form)

                    let phonePattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
                    let emailPattern = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/

                    this.confirmPhone = phonePattern.test(this.form.login)
                    this.confirmMail = emailPattern.test(this.form.login)
                    this.setTimer()
                    /* Выводим уведомление при закртии окна, после отправки кода */
                    window.onbeforeunload = function() {
                        return "Данные не сохранены. Перейти?"
                    }

                } catch(e) {
                    this.error = this.$ErrorsMode(e)
                }

                this.loading = false
            } else {
                if((!this.$v.form.password.testPassword || !this.$v.form.confirmPassword.testPassword) && this.$v.form.confirmPassword.sameAsPassword) {
                    this.errorValidClient = 'Пароль должен содержать хотя бы 1 цифру'
                } else if (!this.$v.form.password.required || !this.$v.form.confirmPassword.required || !this.$v.form.login.required) {
                    this.errorValidClient = 'Заполните все поле'
                } else if (!this.$v.form.confirmPassword.sameAsPassword) {
                    this.errorValidClient = 'Пароли не совпадают'
                } else if (!this.$v.form.password.minLength && !this.$v.form.confirmPassword.minLength) {
                    this.errorValidClient = 'Минимальная длина пароля 8 символов'
                }
            }

            
        },

        async sendConfirmLogin() {
            if(this.timer == 0) {
                this.setTimer()
                try {
                    await this.$store.dispatch('sendConfirmLogin', {
                        login: this.form.login
                    })
                } catch(e) {
                    this.error = this.$ErrorsMode(e)
                }
            }
        },

        setTimer() {
            this.timer = 30
            let interval = setInterval(() => {
                if(this.timer > 0) {
                     this.timer--
                } else {
                    clearInterval(interval)
                }
            }, 1000);

        },

        async confirmLogin() {
            this.error = ''

            try {
                let registerRegister = await this.$store.dispatch('confirmLogin', {
                    login: this.form.login,
                    code: this.confirmCode
                })
                
                let __token = await this.$store.dispatch('accountAuthorize', {
                    userName: registerRegister.userName,
                    token: registerRegister.token
                })

                /* Отключаем уведомление при закрытии окна */
                window.onbeforeunload = null

                window.$cookies.set("token_user_personal_PanelJFservice", __token.bearer,null, null, process.env.coockieUrl)

                window.location.replace(process.env.VUE_APP_REDIRECT_URL)
            } catch(e) {
                this.error = this.$ErrorsMode(e)
            }

        },

        openModal() {
            this.value = true
            this.error = ''
            this.form.login = ''
            this.form.password = ''
            this.form.confirmPassword = ''
        }

    },
    components: {
        dialogBlock, loading
    },
    validations: {
        form: {
            login: {
                required,
            },
            password: {
                required,
                minLength: minLength(8),
                testPassword
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password'),
                minLength: minLength(8),
                testPassword
            },
        },
        confirmCode: {
            required
        }
    },
};
</script>

<style>
    .reset-code span {
        color: #4394e6;
        cursor: pointer;
    }
</style>