<template>
    <div class="form_cell input_app">
        <input class="input_v1" :class="error ? 'app_eror-input' : ''" :value="value" :type="type" :placeholder="placeholder"  @input="changeInput($event.target.value)"/>
        <div style="height: 15px;position: relative;top: 5px; overflow: hidden;">
            <div class="form_cell-error-input shake" v-show="error && !errorMinLength">{{ errorText }}</div>
            <div class="form_cell-error-input shake" v-show="errorMinLength">Минимальная длина {{ minLength }} символов</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        error: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        errorText: {
            type: String,
            default: 'Заполните это поле'
        },
        errorMinLength: {
            type: Boolean,
            default: false
        },
        minLength: {
            type: Number,
            default: 1000
        },
    },
    methods: {
        changeInput(val) {
             this.$emit("input", val);
        }
    }
}
</script>

<style lang="sass" scoped>
    @keyframes shake
        0%, 100%
            -webkit-transform: translate3d(0, 0, 0)
            -ms-transform: translate3d(0, 0, 0)
            transform: translate3d(0, 0, 0)
        
        10%, 30%, 50%, 70%, 90%
            -webkit-transform: translate3d(-3px, 0, 0)
            -ms-transform: translate3d(-3px, 0, 0)
            transform: translate3d(-3px, 0, 0)
        
        20%, 40%, 60%, 80%
            -webkit-transform: translate3d(3px, 0, 0)
            -ms-transform: translate3d(3px, 0, 0)
            transform: translate3d(3px, 0, 0)
        
    .shake
        animation-name: shake
        animation-duration: 1s
        animation-fill-mode: both
        animation-iteration-count: 1
        margin-top: 10px
        color: red
    
    .input_app
        float: none
        flex-direction: column
        .form_cell-error-input
            text-align: left
            font-size: 12px
            margin: 0

    .app_eror-input
        border: 1px solid red
</style>
