<template>
    <div class="block-reset">
        <dialogBlock v-model="value">
            <template slot="activator">
                <span class="activator-link" @click="openModal()">Забыли пароль?</span>
            </template>
            <div slot="header">
                <div class="modal-header">Восстановление пароля</div>
            </div>
            <div slot="body">
                <div class="modal-body" v-if="!tryBlock" >
                    <form action="#" @submit.prevent>

                        <appInput 
                            v-model="$v.login.$model"
                            :error="$v.login.$error"
                            type="text"
                            placeholder="Номер телефона или почта"
                        />

                        <div class="form_cell">
                            <button class="btn_v1 login_btn btn_orange" @click.prevent="resetPass">
                                {{ load ? '' : 'Восстановить' }}
                                <loading v-if="load" />
                            </button>
                        </div>
                        <span class="span_v1 error" v-if="!validLogin">Некорректный телефон или почта</span>
                        <span class="span_v1 error" v-for="(error, index) in error" :key="index"> {{ error.message }} </span>

                        <confirmLoading 
                            v-if="getLoginNoConfirmed"
                            :login="login"
                            :password="''"
                            @resetError="resetError"
                            />
                    </form>
                </div>
                <div class="modal-body" v-if="tryBlock">
                    <div style="font-size: 14px; text-align: center;">Вам был отправлен код для сброса пароля. Введите полученный код и новый пароль для {{ login }}.</div>
                    <form action="#" style="margin: 10px 0;" @submit.prevent>

                        <appInput 
                            v-model="$v.form.codeLogin.$model"
                            :error="$v.form.codeLogin.$error"
                            type="text"
                            placeholder="Код подтверждения"
                        />

                        <appInput 
                            v-model="$v.form.password.$model"
                            :error="!$v.form.password.required && $v.form.password.$dirty"
                            type="password"
                            placeholder="Пароль"
                        />

                        <appInput 
                            v-model="$v.form.confirmPassword.$model"
                            :error="!this.$v.form.confirmPassword.required && this.$v.form.confirmPassword.$dirty"
                            type="password"
                            placeholder="Повторите пароль"
                        />

                        <div class="form_cell">
                            <button class="btn_v1 login_btn btn_orange" @click.prevent="resetPassword">
                                {{ loadReset ? '' : 'Подтвердить' }}
                                <loading v-if="loadReset" />
                            </button>
                        </div>
                        <span class="span_v1 error" v-if="errorValidClient">{{ errorValidClient }}</span>
                        <span class="span_v1 error" v-for="(error, index) in error" :key="index"> {{ error.message }} </span>
                    </form>
                    <modalOrganizationList
                        v-show="openListOrganization"
                        :organizationList="organizationList"
                        :clientLogin="clientLogin"
                        @closeModalOrganization="closeModalOrganization"
                    />
                </div>
            </div>
        </dialogBlock>
    </div>
</template>

<script>
import dialogBlock from '@/components/modal/modal-global'
import loading from '@/components/loading'
import modalOrganizationList from '@/components/modal/modal-organization-list'
import { required, minLength, between, sameAs } from 'vuelidate/lib/validators'
import confirmLoading from '@/components/modal/modal-confirmLogin'
import { mapGetters } from 'vuex'

let testPassword = (val) => {
    let test = val ? /\d/.test(val) : true
    return test
}

export default {
  data() {
        return {
            value: false,
            login: '',
            
            tryBlock: false,
            validLogin: true,
            load: false,
            loadReset: false,
            organizationList: [],
            openListOrganization: false,
            form: {
                codeLogin: '',
                password: '',
                confirmPassword: ''
            },
            error: [],
            errorValidClient: '',
            clientLogin: {
                userName: '',
                token: '',
                organizations: []
            },
        };
    },
    props: {

    },
    computed: {
        ...mapGetters({
			getLoginNoConfirmed: 'getLoginNoConfirmed'
		})
    },
    mounted() {

    },
    methods: {
        async resetPass() {
            this.$store.commit('mutLoginNoConfirmed', false)//сбрасываем флаг подтверждения
            this.$v.login.$touch()

            this.validLogin = true
            this.error = []
            this.errorValidClient = ''

            let phonePattern = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
            let emailPattern = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/

            let testPhone = phonePattern.test(this.login)
            let testMail = emailPattern.test(this.login)

            if(testPhone || testMail) {
                this.load = true
                try {
                    await this.$store.dispatch('sendResetPasswordCode', {
                        login: this.login
                    })
                    this.tryBlock = true

                } catch(e) {
                    this.error = this.$ErrorsMode(e)
                }
                this.load = false
            } else {
                this.validLogin = false
            }

        },


        async resetPassword() {
            this.$v.form.codeLogin.$touch()
            this.$v.form.password.$touch()
            this.$v.form.confirmPassword.$touch()
            this.errorValidClient = ''
            this.error = []

            if(!this.$v.form.$invalid) {
                this.loadReset = true

                try {
                    let query = await this.$store.dispatch('resetPassword', {
                        login: this.login,
                        code: this.form.codeLogin,
                        password: this.form.password,
                        confirmPassword: this.form.confirmPassword
                    })
                    this.curentCode = true

                    this.clientLogin.userName = query.userName
                    this.clientLogin.token = query.token
                    this.clientLogin.organizations = query.organizations
                    
                    if(query.organizations.length > 0) {
                        this.organizationList = query.organizations
                        this.openListOrganization = true
                    } else {
                        var __token = await this.$store.dispatch('accountAuthorize', {
                            userName: query.userName,
                            token: query.token,
                        })
                    }
                    
                    let dateExp = new Date() / 1000  +  (60 * 60 * 24 * 30, 60 * 60 * 24 * 30)
                    window.$cookies.set("time_token_die", dateExp, 60 * 60 * 24 * 30, null, process.env.coockieUrl)
                    window.location.replace(process.env.VUE_APP_REDIRECT_URL)
                } catch(e) {
                    this.curentCode = false
                     this.error = this.$ErrorsMode(e)
                }
                this.loadReset = false
            } else {
                if((!this.$v.form.password.testPassword || !this.$v.form.confirmPassword.testPassword) && this.$v.form.confirmPassword.sameAsPassword) {
                    this.errorValidClient = 'Пароль должен содержать хотя бы 1 цифру'
                } else if (!this.$v.form.password.required || !this.$v.form.confirmPassword.required || !this.$v.form.codeLogin.required) {
                    this.errorValidClient = 'Заполните все поле'
                } else if (!this.$v.form.confirmPassword.sameAsPassword) {
                    this.errorValidClient = 'Пароли не совпадают'
                } else if (!this.$v.form.password.minLength && !this.$v.form.confirmPassword.minLength) {
                    this.errorValidClient = 'Минимальная длина пароля 8 символов'
                }
                
            }
        },
        openModal() {
            this.error = ''
            this.value = true
            this.validLogin = true
            this.login = '',
            this.form.codeLogin = ''
            this.tryBlock = false
            this.$v.$reset()
        },
        closeModalOrganization() {
            this.openListOrganization = false
        },
        resetError() {
            this.error = ''
        }
    },
    components: {
        dialogBlock, loading, modalOrganizationList, confirmLoading
    },
    watch: {
        value(val) {
            if(!val) {
                this.$emit('closeModalOrganization')
            }
        }
    },
    validations: {
        login: {
            required,
        },

        form: {
            codeLogin: {
                required,
            },
            password: {
                required,
                minLength: minLength(8),
                testPassword
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password'),
                minLength: minLength(8),
                testPassword
            },
        }
    }
};
</script>
