export default {
	install (Vue) {
		Vue.prototype.$errorsMod = function (methodOptions) {

			if (process.env.NODE_ENV === 'development') {
				try {
					console.log('error development', methodOptions)
					console.log('error development data', methodOptions.response)
					console.log('error development header', methodOptions.response.headers)
					console.log('methodOptions.status', methodOptions.response.status)
					console.log('error development array', Array.isArray(methodOptions.data))
				} catch(e) {
					console.log(e)
				}
			}

			

			
			if (methodOptions === undefined) {
				//this.$router.push({ path: '404', params: { status: 0 }})
				store.commit('organizationBlocked', true);
				return true
			} else {
				if (methodOptions.response.status === 400) {
					this.$router.push({ name: '404', params: { status: 400 }})
				} else if (methodOptions.response.status === 401) {
					window.$cookies.remove('time_token_die', '/', process.env.VUE_APP_COOCKIE)
					window.location.replace(process.env.VUE_APP_REDIRECT)
				} else if (methodOptions.response.status === 404) {
					if(methodOptions.response.headers['x-reason'] == 'blocked') {
						this.$store.commit('organizationBlocked', true);
					} else {
						this.$router.push('404')
					}
				} else {
					this.$router.push('404')
				}
			}
		}
	}
}
