<template>
	<ul id="demo">
		<treeItem
			class="item documentation-menu"
			:item="itemsTree"
			>
		</treeItem>
	</ul>
</template>


<script>
	import treeItem from "./tree-item";

	export default {
		data() {
			return {
				treeData: {
					label: "root",
					isFolder: true,
					children: []
				}
			};
		},
		props: {
			itemsTree: {
				type: Object,
				default: () => {
					return {
						label: "root",
						isFolder: true,
						children: []
					}
				}
			}
		},
		methods: {


		},
		mounted() {
			
		},
		components: {
			treeItem
		}
	};
</script>

<style>
.item {
  cursor: default;
  position: relative;
  z-index: 10;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}
</style>
