<template>

        <dialogBlock v-model="value">
            <template slot="activator">
                <a @click="openModal()" class="main-office">личный кабинет</a>
            </template>
            <div slot="header">
                <div class="modal-header">Вход в личный кабинет</div>
            </div>
            <div slot="body">
                <form action="#" @submit.prevent>
                    <div class="modal-body">

                        <appInput 
                            v-model.trim="$v.form.login.$model"
                            :error="!!!$v.form.login.$model && $v.form.login.$dirty"
                            type="text"
                            placeholder="Номер телефона или почта"
                        />
                        <appInput 
                            v-model.trim="$v.form.password.$model"
                            :error="$v.form.password.$error"
                            type="password"
                            placeholder="Пароль"
                        />
                            
                        <span class="span_v1 error" v-for="(error, index) in error" :key="index"> {{ error.message }} </span>

                        <confirmLoading 
                            v-if="getLoginNoConfirmed"
                            :login="form.login"
                            :password="form.password"
                            @resetError="resetError"
                            />

                        <div class="form_cell">
                            <button class="btn_v1" @click="loginUser">
                                <span v-if="!loading">Войти</span>
                                <loading v-else />
                            </button>
                        </div>

                    </div>
                </form>
                <div class="footer-modal">
                    <modalRegistry />
                    <modalResetPas />
                </div>
                <modalOrganizationList
                    v-show="openListOrganization"
                    :model="openListOrganization"
                    :organizationList="organizationList"
                    :clientLogin="clientLogin"
                    @closeModalOrganization="closeModalOrganization"
                />
            </div>

        </dialogBlock>

</template>

<script>
    let testPhone = (val) => {
        let test = val ? /^((\+7|7|8)+([0-9]){10})$/.test(val) : true
        return test
    }

    import { required, minLength, between, email } from 'vuelidate/lib/validators'

    import dialogBlock from '@/components/modal/modal-global'
    import modalRegistry from '@/components/modal/modal-register'
    import modalResetPas from '@/components/modal/modal-resetPas'
    import loading from '@/components/loading'
    import confirmLoading from '@/components/modal/modal-confirmLogin'
    import modalOrganizationList from '@/components/modal/modal-organization-list'
    import { mapGetters } from 'vuex'

    export default {
    data() {
            return {
                value: false,
                modal: false,
                loading: false,
                error: '',
                form: {
                    login: '',
                    password: ''
                },
                organizationList: [],
                openListOrganization: false,
                clientLogin: {
                    userName: '',
                    token: '',
                    organizations: []
                },
                confirmEmeilModal: false, /* Флаг если пользователь ввел неподтвержденный логин */
            }
        },
        props: {

        },
        computed: {
            ...mapGetters({
				getLoginNoConfirmed: 'getLoginNoConfirmed'
			})
        },
        mounted() {

        },
        methods: {
            async loginUser() {
                this.loading = true

                this.$v.form.login.$touch()
                this.$v.form.password.$touch()

                if((this.$v.form.login.email || this.$v.form.login.testPhone) && !this.$v.form.password.$error) {

                    try {
                        this.$store.commit('mutLoginNoConfirmed', false)//сбрасываем флаг подтверждения

                        let query = await this.$store.dispatch('login', this.form)

                        this.clientLogin.userName = query.userName
                        this.clientLogin.token = query.token
                        this.clientLogin.organizations = query.organizations

                        if(query.organizations.length > 0) {
                            this.organizationList = query.organizations
                            this.openListOrganization = true
                        } else {
                            var __token = await this.$store.dispatch('accountAuthorize', {
                                userName: query.userName,
                                token: query.token,
                            })

                            let dateExp = new Date() / 1000  +  (60 * 60 * 24 * 30, 60 * 60 * 24 * 30)
                            window.$cookies.set("time_token_die", dateExp, 60 * 60 * 24 * 30, null, process.env.coockieUrl)
                            
                            window.location.replace(process.env.VUE_APP_REDIRECT_URL)
                        }
                        
                        
                    } catch(e) {
                        this.error = this.$ErrorsMode(e)
                    }
      
                } else {
                   this.error = [{message: 'Логин должен быть email или телефон'}]
                }


                this.loading = false
            },

            closeModalOrganization() {
                this.openListOrganization = false
            },

            openModal() {
                this.value = true
                this.error = ''
                this.form.login = ''
                this.form.password = ''
            },
            resetError() {
                this.error = ''
            }

        },

        components: {
            dialogBlock, loading, modalRegistry, modalResetPas, confirmLoading, modalOrganizationList
        },
        validations: {
            form: {
                login: {
                    required,
                    email,
                    testPhone
                },
                password: {
                    required,
                    
                }
            }
        }
    };
</script>

<style>
    .main-office {
        cursor: pointer;
    }

    .footer-modal {
        margin: 20px 0;
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
    }

    .activator-link {
        color: #4a4a4a;
        cursor: pointer;
    }

    .activator-link:hover {
        color: #217fbc;
    }
</style>
