<template>
    <header class="header" :class="openMenuClass ? 'open-menu' : ''">
    	<div class="wrapper">
    		<nav>
    			<div class="menu-bar" @click="openMenuClass = !openMenuClass">
    				<span></span>
    			</div>
				
    			<ul :style="openMenuClass ? 'left: 0' : ''" @click="openMenuClass = false">
					<li><router-link to="/">Главная</router-link></li>
					<li><router-link to="/about">О нас</router-link></li>
					<li><router-link to="/news">Новости</router-link></li>
					<li><router-link to="/documents">Документы</router-link></li>
					<li><router-link to="/vacancies">Вакансии</router-link></li>
					<li><router-link to="/contacts">Контакты</router-link></li>
    			</ul>
    		</nav>
    	</div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			openMenuClass: false
		}
	},
	computed: {
		...mapGetters([
			
		])
	},

	methods: {
		openMenu() {
			this.openMenuClass = !this.openMenuClass
		}
	}
}

</script>
