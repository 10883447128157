<template>
    <appGloballayout
        :loading="loading"
        :error="error"
        @reload="getVacancies"
    >
		<section class="content" slot=body>
			<div class="wrapper">
				<h2 class="content-title">Вакансии</h2>
				<div class="vacancies-row">
					<template v-if="vacanciesList.length > 0">
						<div class="vacancies-column" v-for="(item, index) in vacanciesList" :key="index">
							<h3 class="vacancies-column__title">{{ item.name }}<span>{{ dateReturnFormat(item.date) }}</span></h3>
							<div :class="getOrgSettings.template == 'template-1' ? 'vacancies-column__row' : ''">
								<p v-html="item.description"></p>
								<div class="vacancies-column__info">
									<p>ЗП: {{ item.wage }}</p>
									<span>График: {{ item.schedule }}</span>
								</div>
							</div>
							<!-- <a class="vacancies-column__name" href="#">Название организаци</a> -->
						</div>
					</template>
					<template v-else>
						Вакансии отсутствуют
					</template>
				</div>
			</div>
		</section>
    </appGloballayout>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { dateReturnFormat } from '@/plugins/methods'

export default {
    data() {
        return {
            vacanciesList: [],
			loading: true,
			error: false
        }
    },

    async mounted() {
        this.getVacancies()
    },

	methods: {
		dateReturnFormat(date) {
			return dateReturnFormat(date)
		},
		async getVacancies() {
			this.loading = true
			try {
				this.vacanciesList = await this.$store.dispatch('getVacancy')
			} catch(e) {
				this.error = this.$errorsMod(e)
			}
			setTimeout(() => {
				this.loading = false
			}, 200);
		}
	},
    computed: {
    	...mapGetters([
			'getOrgSettings'
		])
    },
}
</script>
