<template>
    <div>
        <span>
            <slot name="activator">открыть</slot>
        </span>
        <transition name="modal">
            <div class="modal-mask" @click="clickMask($event)" v-show="value">
                <div class="modal-wrapper" :style="styleBlock">
                    <div 
                        class="modal-container" 
                        :style="{
                            transform: 'scale('+ noExitMask+')',
                            width: width+'px',
                            position: position,
                            left: left
                        }"
                        >
                        <br>
                        <slot name="header">

                        </slot>
                        <button class="modal-default-button" @click="modalShow(false)"></button>

                        <slot name="body">
                            <div></div>
                        </slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                showModal: false,
                noExitMask: 1
            };
        },

        props: {
            value: {
                default: false,
                type: Boolean
            },
            width: {
                type: Number,
                default: 320
            },
            styleBlock: {
                type: String,
                default: ''
            },
            position: {
                type: String,
                default: 'relative'
            },
            left: {
                type: String,
                default: '0'
            },
        },
        methods: {
            modalShow(value) {
                this.$emit("input", value);
            },
            clickMask(e) {
                if(e.target.className == 'modal-wrapper') {
                    this.noExitMask = 1.05
                    setTimeout(()=> {
                        this.noExitMask = 1
                    }, 100)
                }
            }
        },
    };
</script>


<style>
    .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
    transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .modal-container {
        transform: scale(1.0);
        transition: 0.5s;
        width: 300px;
        margin: 0px auto;
        padding: 20px 0px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        position: relative;
    }

    .modal-header {
    margin-top: 0;
    color: #000;
    font-size: 20px;
    }

    .modal-body {
        margin: 20px 0;
        padding: 0 30px;
    }
    .modal-footer {
    margin: 0px 0;
    padding: 10px 30px;
    }

    .modal-default-button {
        float: right; 
        background: #FFF;
        padding: 10px 9px;
        font-size: 15px;
        border-radius: 3px;
        color: #FFF;
        height: 35px;
        width: 35px;
        border-radius: 50px;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        right: 3px;
        top: 3px;
        border: none;
        cursor: pointer;
    }
    .modal-default-button::after {
        content: " ";
        width: 20px;
        background: #000;
        height: 1px;
        display: block;
        transform: rotate(45deg)
    }
    .modal-default-button::before {
        content: " ";
        width: 20px;
        background: #000;
        height: 1px;
        display: block;
        transform: rotate(-45deg)
    }


    .modal-header {
        text-align: center;
    }
    /*
        * The following styles are auto-applied to elements with
        * transition="modal" when their visibility is toggled
        * by Vue.js.
        *
        * You can easily play with the modal transition by editing
        * these styles.
        */

    .modal-enter {
    opacity: 0;
    }

    .drivider {
        border-bottom: 1px solid #ccc;
        width: 100%;
        padding: 10px 0 0 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    .input_v1 {
        width: 100%;
        height: 30px;
        border-radius: 2px;
        border: 1px solid #CCC;
        color: #303030;
        font-size: 15px;
        padding: 10px;
    }
    .form_cell {
        margin: 0 0 20px 0;
    }

    .btn_v1 {
        width: 100%;
        height: 40px;
        background: #4394E6;
        border: none;
        color: #FFF;
        font-size: 14px;
        cursor: pointer;
    }

    .btn_v1:hover {
        opacity: 0.8;
    }

    input::placeholder {
        color: #000;
        font-size: 12px;
    }

    .modal-body .error {
        color: red;
        text-align: center;
        display: block;
        margin: 10px 0;
    }
</style>
