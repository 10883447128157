<template>
	<div id="app" class="template-one">
		<template v-if="getOrganizationBlocked">
			<OrganizationBlocked />
		</template>
		<template v-else>
			<HeaderBlock />
			<router-view />
			<FooterBlock />
		</template>

	</div>
</template>

<script>
import HeaderBlock from '@/components/template/header/header'
import FooterBlock from '@/components/template/footer/footer'
import { mapGetters } from 'vuex'
import OrganizationBlocked from './components/organization-blocked'

export default {
	data() {
		return {

		};
	},
	components: {
		HeaderBlock,
		FooterBlock,
		OrganizationBlocked,
	},
	computed: {
		...mapGetters([
			'getOrganizationBlocked'
		])
	},
	async beforeCreate() {
		try {
			let version = window.$cookies.get('version_site')

			if(version === '1') {
				window.document.getElementById('app').classList.add('version-switcher');
			}

			await this.$store.dispatch('getGetInfo')
			await this.$store.dispatch('getSettings')
			setTimeout(() => {
				document.getElementsByClassName('main')[0].style.opacity = '1';
			}, 10);
			
		} catch(e) {
			this.$errorsMod(e)
		}
	}
};
</script>

<style>
	html {
		height: 100%;
	}

	body {
		height: 100%;
	}

	#app {
		width: 100%;
		height: 100%;
		flex-direction: column;
		margin: 0;
		min-height: 100%;
		display: flex;
	}
</style>

