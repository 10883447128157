import axios from 'axios';

const $axios = axios.create({
    baseURL: process.env.VUE_APP_URL,
    timeout: 100000,
    withCredentials: true,
});

export const $axiosAuth = axios.create({
    baseURL: process.env.VUE_APP_URL_AUTH+'web',
    timeout: 10000,
    withCredentials: true
});

export default $axios;