import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import sass from './assets/sass/main1.sass'
import sass2 from './assets/sass/main2.sass'
import VueCookies from 'vue-cookies'
import errorsMod from '@/plugins/errors.js'
import Vuelidate from 'vuelidate'
import appInput from '@/components/appInput'
import vgloballayout from '@/components/appGlobalLayout'

Vue.config.productionTip = false

Vue.component('appInput', appInput)
Vue.component('appGloballayout', vgloballayout)

Vue.use(VueCookies)
Vue.use(errorsMod)
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  sass,
  sass2,
  render: h => h(App)
}).$mount('#app')
